const main = document.querySelector('#main')
const inquiryModals = document.querySelectorAll('.js-inquiryModal')

/**
 * handle opening modal on click
 * @param event
 * @returns {void}
 */
function handleOpenModal(event) {
  if (event.target.classList.contains('js-openModal') && event.target.classList.contains('is-active')) {

    inquiryModals.forEach((modal) => {
      modal.classList.add('is-active')
      modal.setAttribute('aria-hidden', 'false')

      if (!main.classList.contains('has-modal')) {
        main.classList.add('has-modal')
      }
    })
  }
}

window.addEventListener('click', handleOpenModal)
